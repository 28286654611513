import styled, { css } from 'styled-components'
import Link from 'gatsby-link'

import {
  StyledShadowedArrowPrev,
  StyledShadowedArrowNext,
} from '../SliderNavigation/ShadowedArrows/styles'
import media, { customMedia } from '../../../common/MediaQueries'

const StyledGallerySliderWrapper = styled.div`
  overflow: hidden;

  ${StyledShadowedArrowPrev},
  ${StyledShadowedArrowNext} {
    display: none;
  }

  ${StyledShadowedArrowPrev} {
    left: 1.5rem;
  }

  ${StyledShadowedArrowNext} {
    right: 1.5rem;
  }

  @media ${media.phone} {
    position: relative;

    ${({ alt }) =>
      alt &&
      css`
        ${StyledShadowedArrowPrev},
        ${StyledShadowedArrowNext} {
          display: flex;
          color: ${({ theme }) => theme.colors.white};
        }
      `}
  }

  @media ${media.tablet} {
    ${({ alt }) =>
      alt &&
      css`
        ${StyledShadowedArrowPrev} {
          left: 2.125rem;
        }

        ${StyledShadowedArrowNext} {
          right: 2.125rem;
        }
      `}

    ${({ alt }) =>
      !alt &&
      css`
        ${StyledShadowedArrowPrev} {
          left: 1.5rem;
        }

        ${StyledShadowedArrowNext} {
          right: 1.5rem;
        }
      `}
  }
`

const StyledGallerySliderInner = styled.div`
  display: flex;
`

const StyledGallerySliderSlide = styled(Link)`
  position: relative;
  flex: 0 0 100%;
  overflow: hidden;
  padding-left: 0.625rem;

  @media ${customMedia.maxPhone} {
    .gatsby-image-wrapper {
      width: calc(100% - 0.625rem) !important;
    }
  }

  @media ${media.phone} {
    flex: 0 0 auto;
  }
`

export {
  StyledGallerySliderWrapper,
  StyledGallerySliderInner,
  StyledGallerySliderSlide,
}
