import styled from 'styled-components'

const StyledGalleryImgTooltip = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
`

const StyledGalleryImg = styled.div``

export { StyledGalleryImg, StyledGalleryImgTooltip }
