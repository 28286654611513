import styled from 'styled-components'
import { rgba } from 'polished'

const StyledTooltip = styled.div`
  background: ${({ theme }) => rgba(theme.colors.white, 0.85)};
  padding: 0.375rem;
`

const StyledTooltipLabel = styled.p`
  letter-spacing: 0.21px;
  font-size: 0.75rem;
`

export { StyledTooltip, StyledTooltipLabel }
