// Embla Carousel React
import React, { memo, useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { useEmblaCarousel } from 'embla-carousel-react'

import {
  StyledGallerySliderWrapper,
  StyledGallerySliderInner,
  StyledGallerySliderSlide,
} from './styles'
import { fixedPropType } from '../../../constants/proptypes'
import {
  PrevButton,
  NextButton,
} from '../SliderNavigation/ShadowedArrows/ShadowedArrows'
import GalleryImg from '../../GalleryImg/GalleryImg'
import useInterval from '../../../common/hooks/useInterval'

const sliderOptions = {
  align: 'start',
  loop: true,
  draggable: true,
}

const GallerySlider = ({ gallery, alt }) => {
  const [EmblaCarouselReact, embla] = useEmblaCarousel(sliderOptions)
  const [scrollSnaps, setScrollSnaps] = useState([])
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false)
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false)

  const scrollTo = useCallback((index) => embla.scrollTo(index), [embla])
  const scrollPrev = useCallback(() => embla.scrollPrev(), [embla])
  const scrollNext = useCallback(() => embla.scrollNext(), [embla])

  useInterval(() => {
    if (selectedIndex === scrollSnaps.length - 1) {
      scrollTo(0)
    } else {
      scrollNext()
    }
  }, 4000)

  const newGallery =
    gallery.length > 3 && gallery.length < 7 ? gallery.concat(gallery) : gallery

  useEffect(() => {
    if (embla) {
      if (gallery.length < 4) {
        embla.destroy()
        return
      }

      const onSelect = () => {
        setSelectedIndex(embla.selectedScrollSnap())
        setPrevBtnEnabled(embla.canScrollPrev())
        setNextBtnEnabled(embla.canScrollNext())
      }
      setScrollSnaps(embla.scrollSnapList())
      embla.on('select', onSelect)
      onSelect()
    }
  }, [gallery, embla])

  return (
    <StyledGallerySliderWrapper alt={alt}>
      <EmblaCarouselReact>
        <StyledGallerySliderInner>
          {newGallery.map(
            (
              { fixed, title, to, objectPosition, vipImage, width, height },
              index,
            ) => (
              <StyledGallerySliderSlide
                key={index}
                {...(to === null || to === '#' ? { as: 'div' } : { to })}
              >
                <GalleryImg
                  {...{
                    fixed,
                    title,
                    to,
                    objectPosition,
                    vipImage,
                    width,
                    height,
                  }}
                />
              </StyledGallerySliderSlide>
            ),
          )}
        </StyledGallerySliderInner>
      </EmblaCarouselReact>
      <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
      <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
    </StyledGallerySliderWrapper>
  )
}

export default memo(GallerySlider)

GallerySlider.defaultProps = {
  gallery: PropTypes.arrayOf(PropTypes.shape({ to: '/' })),
  alt: false,
}

GallerySlider.propTypes = {
  gallery: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string,
      title: PropTypes.string,
      fixed: fixedPropType,
      objectPosition: PropTypes.string,
    }),
  ),
  alt: PropTypes.bool,
}

export const query = graphql`
  fragment city_gallerySliderFields on wp_City_Acf_ContentBlocks_Content_GallerySlider {
    fieldGroupName
    bg
    images {
      image {
        mediaItemUrl
        imageFile {
          childImageSharp {
            fixed(height: 333) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
      action {
        showAction
        action {
          internalOrExternal
          external {
            target
            title
            url
          }
          internal {
            title
            target {
              ... on wp_Post {
                contentType {
                  node {
                    name
                  }
                }
                slug
              }
              ... on wp_Page {
                contentType {
                  node {
                    name
                  }
                }
                slug
              }
              ... on wp_Model {
                contentType {
                  node {
                    name
                  }
                }
                slug
              }
            }
          }
        }
      }
    }
  }
`
