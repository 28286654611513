import styled from 'styled-components'

import media from '../../common/MediaQueries'
import { Typo5 } from '../Typography/Typography'

const StyledModelDetails = styled.ul`
  width: 100%;
`

const StyledModelDetailsItem = styled.li`
  display: flex;
  flex-wrap: wrap;

  @media ${media.tablet} {
    &:not(:last-child) {
      margin-bottom: 0.25rem;
    }
  }

  @media ${media.desktop} {
    &:not(:last-child) {
      margin-bottom: 0.3125rem;
    }
  }
`

const StyledModelDetailsTitle = styled(Typo5)`
  width: 7.25rem;
  position: relative;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-grow: 1;

  &::after {
    content: ':';
    font-size: 0.875rem;
    margin-bottom: 0.25rem;
  }
`

const StyledModelDetailsData = styled(Typo5)`
  flex-grow: 1;
  padding-left: 1.75rem;
  width: calc(100% - 7.25rem);
`

export {
  StyledModelDetails,
  StyledModelDetailsItem,
  StyledModelDetailsTitle,
  StyledModelDetailsData,
}
