import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { StyledGalleryImg, StyledGalleryImgTooltip } from './styles'
import Image from '../Image/Image'
import Tooltip from '../Tooltip/Tooltip'
import { fixedPropType } from '../../constants/proptypes'

const GalleryImg = ({
  fixed,
  objectPosition,
  title,
  vipImage,
  width,
  height,
}) => {
  const [isVisible, setIsVisible] = useState(false)

  return (
    <StyledGalleryImg
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      <Image {...{ fixed, objectPosition, vipImage, width, height }} />
      {title && isVisible && (
        <StyledGalleryImgTooltip>
          <Tooltip title={title} />
        </StyledGalleryImgTooltip>
      )}
    </StyledGalleryImg>
  )
}

export default GalleryImg

GalleryImg.propTypes = {
  title: PropTypes.string,
  fixed: fixedPropType,
  objectPosition: PropTypes.string,
}
