import React, { useState, useCallback, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'

import GallerySlider from '../../../components/Sliders/GallerySlider/GallerySlider'
import { buildAction } from '../../utils'
import Container from '../../../components/Container'
import { bgTheme } from '../../utils'

const IMAGE_HEIGHT = 514

const FlexGallerySlider = ({ block, index, alt }) => {
  const [ref, inView] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  })
  const [sliderItems, setSliderItems] = useState()

  // We need images width for slider so we have to fetch them in a bg and set them after the initialization
  const setVipImages = useCallback(async (images) => {
    const promises = await Promise.all(
      images.map(item => {
        return new Promise((resolve, reject) => {
          const src = item.image.mediaItemUrl
          const image = new Image()
          image.height = IMAGE_HEIGHT
          image.style.objectFit = 'cover';

          image.onload = (event) => {
            // we calculate the original ratio and setup the width based on hardoded height
            const { naturalHeight, naturalWidth } = event.currentTarget
            const ratio = naturalWidth / naturalHeight
            const width = IMAGE_HEIGHT * ratio

            resolve(width)
          }
          image.onerror = () => resolve(0)
          image.src = src
        })
      })
    )

    const vipImages = images.map(({ action = {}, image, mobileX }, index) => {

      let blockAction = action?.action ? buildAction(action.action) : undefined

      return {
        to:
          action.showAction !== null && blockAction
            ? blockAction.props.to
            : null,
        title:
          action.showAction !== null
            ? blockAction?.props?.children
            : action.hoverLabel,
        height: IMAGE_HEIGHT,
        width: promises[index],
        objectPosition: mobileX ? `${mobileX}% 50%` : '50% 50%',
        vipImage: image.mediaItemUrl,
      }
    })

    setSliderItems(vipImages)
  }, [])

  useEffect(() => {
    const isVip = !!block.vipImages

    if (isVip && block.vipImages) {
      const imagesData = block.vipImages
      setVipImages(imagesData)
    } else {
      const data = block.images.map(({ action = {}, image, mobileX }) => {
        let blockAction = action?.action ? buildAction(action.action) : undefined

        return {
          to:
            action.showAction !== null && blockAction
              ? blockAction.props.to
              : null,
          title:
            action.showAction !== null
              ? blockAction?.props?.children
              : action.hoverLabel,
          fixed: image.imageFile ? image.imageFile.childImageSharp.fixed : null,
          objectPosition: mobileX ? `${mobileX}% 50%` : '50% 50%',
        }
      })

      setSliderItems(data)
    }
  }, [block])

  const gallerySliderBg = bgTheme(block.vipImagesBg || block.bg)

  if (!sliderItems) {
    return null
  }

  return (
    <Container pt="0.625rem" pb="0.625rem" bg={gallerySliderBg} inView={inView}>
      <div className="uiAnimBottom">
        <div ref={ref}>
          {inView ? (
            <GallerySlider key={index} gallery={sliderItems} alt={alt} />
          ) : null}
        </div>
      </div>
    </Container>
  )
}

export default FlexGallerySlider
