import React from 'react'
import PropTypes from 'prop-types'

import { StyledTooltip, StyledTooltipLabel } from './styles'

const Tooltip = ({ title }) => (
  <StyledTooltip>
    <StyledTooltipLabel>{title}</StyledTooltipLabel>
  </StyledTooltip>
)

export default Tooltip

Tooltip.propTypes = {
  title: PropTypes.string.isRequired,
}
