import React from 'react'
import PropTypes from 'prop-types'

import {
  StyledModelDetails,
  StyledModelDetailsItem,
  StyledModelDetailsTitle,
  StyledModelDetailsData,
} from './styles'

const ModelDetails = ({ details }) => (
  <StyledModelDetails>
    {details.map(({ title, data }) => (
      <StyledModelDetailsItem key={title}>
        <StyledModelDetailsTitle fontWeight="bold">
          {title}
        </StyledModelDetailsTitle>
        <StyledModelDetailsData>{data}</StyledModelDetailsData>
      </StyledModelDetailsItem>
    ))}
  </StyledModelDetails>
)

export default ModelDetails

ModelDetails.propTypes = {
  details: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      data: PropTypes.string,
    }),
  ).isRequired,
}
